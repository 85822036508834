import React from 'react'
import { Link } from 'gatsby'

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Buttons,
  Checkbox,
  CheckboxGroup,
  Col,
  Columns,
  Detail,
  Divider,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  Inline,
  Input,
  Lead,
  Main,
  Paragraph,
  PostponeIcon,
  Row,
  SideStep,
  SideSteps,
  SkipLink,
  Spacer,
  StatusBar,
  StatusBarItem,
  Textarea,
  Wrapper,
  XIcon
} from '@te-digi/styleguide'

import { HeaderExample } from '../HeaderExamples'
import { FooterExample } from '../FooterExample'

type ExampleProps = {
  className?: string
}

const MultiPageFormNavigationExample = ({ className }: ExampleProps) => (
  <>
    <SkipLink />
    <Wrapper className={className}>
      <HeaderExample
        activePrimaryItem="Omat sivut"
        hasUser
        hasCompany
      />
      <Columns
        layout="sidebar"
        sidebar={
          <div data-note="1">
            <SideSteps label="Lomakkeen pääotsikko">
              <SideStep
                as={Link}
                status="complete"
                to="#1"
              >
                Ensimmäinen sivu (valmis)
              </SideStep>
              <SideStep
                active
                as={Link}
                to="#2"
              >
                Toinen sivu (aktiivinen)
              </SideStep>
              <SideStep
                as={Link}
                to="#3"
              >
                Kolmas sivu (perustila)
              </SideStep>
              <SideStep
                as={Link}
                subSteps={
                  <>
                    <SideStep
                      as={Link}
                      to="#4a"
                    >
                      Ensimmäinen vaihe
                    </SideStep>
                    <SideStep
                      as={Link}
                      to="#4b"
                    >
                      Toinen vaihe
                    </SideStep>
                  </>
                }
                to="#4a"
              >
                Alavaiheiden otsikko
              </SideStep>
              <SideStep
                as={Link}
                disabled
                to="#5"
              >
                Viides sivu (estetty)
              </SideStep>
              <SideStep
                as={Link}
                status="error"
                to="#6"
              >
                Kuudes sivu (virheellinen)
              </SideStep>
              <SideStep
                as={Link}
                to="#3"
              >
                Tietojen lähetys
              </SideStep>
            </SideSteps>
          </div>
        }
      >
        <Main>
          <Heading>Toinen sivu</Heading>
          <Spacer marginBottom="xxl">
            <Lead>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              accumsan tortor nec est ornare bibendum. Etiam a scelerisque nisl,
              vel aliquam libero. Phasellus vehicula diam et placerat feugiat.
            </Lead>
          </Spacer>
          <FormLayout>
            <FormLayoutItem
              header={
                <>
                  <Heading
                    size={3}
                    level={3}
                  >
                    Ensimmäisen osion otsikko
                  </Heading>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla accumsan tortor nec est ornare bibendum. Etiam a
                    scelerisque nisl, vel aliquam libero. Phasellus vehicula
                    diam et placerat feugiat.
                  </Paragraph>
                </>
              }
            >
              <Row>
                <Col
                  xs={12}
                  md={6}
                >
                  <FormGroup>
                    <Detail heading="Aliquam">
                      Sed congue augue vitae neque
                    </Detail>
                  </FormGroup>
                </Col>
                <Col
                  xs={12}
                  md={6}
                >
                  <FormGroup>
                    <Detail heading="Erat volutpat">
                      Proin interdum maecenas massa
                    </Detail>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Input
                  label="Lorem ipsum dolor sit amet"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Textarea label="Nulla accumsan tortor" />
              </FormGroup>
              <CheckboxGroup
                label="Phasellus vehicula diam"
                onChange={() => {
                  // This is intentional
                }}
              >
                <Checkbox>Etiam a scelerisque nisl</Checkbox>
                <Checkbox>Vel aliquam libero</Checkbox>
              </CheckboxGroup>
            </FormLayoutItem>
          </FormLayout>
          <Spacer marginTop="xl">
            <div data-note="2">
              <Inline justifyContent="between">
                <Button
                  iconLeft={<ArrowLeftIcon />}
                  variant="outline"
                >
                  Edellinen
                </Button>
                <Button iconRight={<ArrowRightIcon />}>Seuraava</Button>
              </Inline>
            </div>
          </Spacer>
          <Divider />
          <Buttons align="center">
            <Button
              iconLeft={<XIcon />}
              variant="plain"
            >
              Keskeytä
            </Button>
            <Button
              iconLeft={<PostponeIcon />}
              variant="plain"
            >
              Jatka myöhemmin
            </Button>
          </Buttons>
        </Main>
      </Columns>
      <StatusBar
        data-id="statusbar"
        saveStatus="saved"
      >
        <StatusBarItem>Et ole vielä lähettänyt ilmoittautumista</StatusBarItem>
      </StatusBar>
      <FooterExample />
    </Wrapper>
  </>
)

export { MultiPageFormNavigationExample }
