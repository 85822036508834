import React from 'react'

import { Head } from '../../components/Head'
import { MultiPageFormNavigationExample } from '../../examples/react/pages/MultiPageFormNavigationExample'

const Page = () => (
  <>
    <Head title="Monisivuisen lomakkeen navigaatio" />
    <MultiPageFormNavigationExample />
  </>
)

export default Page
